import { useSelector } from "react-redux";
import { AppActions } from "../modules/actions";
import { RootState } from "../store/rootState";
import { useAction } from "../utils/hooks/action";
import NaturalTitaniumImage from "../assets/naturaltitanium.jpeg";
import BlueTitaniumImage from "../assets/bluetitanium.jpeg";
import WhiteTitaniumImage from "../assets/whitetitanium.jpeg";
import BlackTitaniumImage from "../assets/blacktitanium.jpeg";

export const ProColorSelection = () => {
    const { color } = useSelector((state: RootState) => state.app);
  const setColor = useAction(AppActions.setColor);
  
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
            onClick={() => {
              setColor('Desert Titanium');
            }}
            className={`color-selection${(color ?? '') === 'Desert Titanium' ? "-selected" : ""}`}
          >
            {'Desert Titanium'}
            {/* <img className="ms-2" style={{width: 20, height: 20, borderRadius: 100}} src={NaturalTitaniumImage}/> */}
          </div>

          <div
            onClick={() => {
              setColor('Natural Titanium');
            }}
            className={`color-selection${(color ?? ' ') === 'Natural Titanium' ? "-selected" : ""}`}
          >
            {'Natural Titanium'}
            {/* <img className="ms-2" style={{width: 20, height: 20, borderRadius: 100}} src={ BlueTitaniumImage}/> */}
          </div>

          <div
            onClick={() => {
              setColor('White Titanium');
            }}
            className={`color-selection${(color ?? '') === 'White Titanium' ? "-selected" : ""}`}
          >
            {'White Titanium'}
            {/* <img className="ms-2" style={{width: 20, height: 20, borderRadius: 100}} src={WhiteTitaniumImage}/> */}
          </div>

          <div
            onClick={() => {
              setColor('Black Titanium');
            }}
            className={`color-selection${(color ?? '') === 'Black Titanium' ? "-selected" : ""}`}
          >
            {'Black Titanium'}
            {/* <img className="ms-2" style={{width: 20, height: 20, borderRadius: 100}} src={BlackTitaniumImage}/> */}
          </div>
    </div>
  );
};
