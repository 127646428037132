import { createAction } from 'deox';
import { resolveConfig } from 'prettier';

export class AppActions {
  static reset = createAction('app/reset');

  static setName = createAction('app/set_name', resolve => (payload: string) => resolve(payload));
  
  static setPhoneNumber = createAction('app/set_phone_number', resolve => (payload: string) => resolve(payload));

  static setWhatsappNumber = createAction('app/set_whatsapp_number', resolve => (payload: string) => resolve(payload));

  static setEmail = createAction('app/set_email', resolve => (payload: string) => resolve(payload));

  static setModel = createAction('app/set_model', resolve => (payload: string) => resolve(payload));

  static setMemory = createAction('app/set_memory', resolve => (payload: string) => resolve(payload));

  static setColor = createAction('app/set_color', resolve => (payload: string) => resolve(payload));

  static setBranch = createAction('app/set_branch', resolve => (payload: string) => resolve(payload));

  static setSeller = createAction('app/set_seller', resolve => (payload: string) => resolve(payload));

  static submit = {
    request: createAction('auth/place_order_request'),
    success: createAction('auth/place_order_success'),
    fail: createAction('auth/place_order_failure', resolve => (error: string) =>
      resolve(error),
    ),
  };
  
  static setMove = createAction('app/set_move', resolve => (payload: boolean) => resolve(payload))
}