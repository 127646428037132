import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import en from './translation/en.json';
import ar from './translation/ar.json';

export const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

i18next.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: "en",
  debug: true,
  resources,
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
}, (err, t) => {
    
});