import { useSelector } from "react-redux/es/hooks/useSelector";
import { AppActions } from "../modules/actions";
import { useAction } from "../utils/hooks/action";
import { RootState } from "../store/rootState";

export const MemorySelection = () => {
    const { model, memory } = useSelector((state: RootState) => state.app);
  const setMemory = useAction(AppActions.setMemory);
  var data = [];
  if((model ?? '').toLowerCase().includes('pro max')) {
    data = ["256GB", "512GB", "1TB"]
  }
  else if((model ?? '').toLowerCase().includes('pro')) {
    data = ["128GB", "256GB", "512GB", "1TB"]
  } 
  else {
    data = ["128GB", "256GB", "512GB"]
  }
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {[
        ...(data ?? []).map((item, index) => (
          <div
            onClick={() => {
              setMemory(item);
            }}
            className={`selection${(memory ?? ((model ?? '').toLowerCase().includes('pro ,ax') ? '256GB' : '128GB')) === item ? "-selected" : ""}`}
          >
            {item}
          </div>
        )),
      ]}
    </div>
  );
};
