import { useTranslation } from "react-i18next";
import HiPhoneLogoImage from "../assets/hiphone-logo.svg";
import MobicomLogoImage from "../assets/mobicom-logo.png";
import PhoneBookLogoImage from "../assets/pb-logo.png";
import HiphoneCareLogoImage from "../assets/hpc-logo.png";
import Promo1Image from "../assets/promo-1.jpg";
import Promo2Image from "../assets/promo-2.jpg";
import Promo3Image from "../assets/promo-3.jpg";
import IPhone15ProImage from "../assets/iphone15-pro.jpg";
import PromoImage from "../assets/promo.jpg";
import { ModelSelection } from "../components/model_selection";
import { MemorySelection } from "../components/memory_selection";
import { Footer } from "../components/footer";
import { useAction } from "../utils/hooks/action";
import { AppActions } from "../modules/actions";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootState";
import { useEffect } from "react";
import { Loading } from "../components/loading";
import { useParams, useSearchParams } from "react-router-dom";
import { ProColorSelection } from "../components/ultra_color_selection";
import { ColorSelection } from "../components/color_selection";

export const RequestDevicePage = () => {
  // Translation
  const { t, i18n } = useTranslation();
  // States
  const {
    name,
    phone_number,
    whatsapp_number,
    email_address,
    model,
    memory,
    loading,
    move,
    color,
    error,
    seller,
    branch
  } = useSelector((state: RootState) => state.app);
  // Actions
  const setName = useAction(AppActions.setName);
  const setEmail = useAction(AppActions.setEmail);
  const setPhoneNumber = useAction(AppActions.setPhoneNumber);
  const setWhatsappNumber = useAction(AppActions.setWhatsappNumber);
  const setMove = useAction(AppActions.setMove);
  const setModel = useAction(AppActions.setModel);
  const setMemory = useAction(AppActions.setMemory);
  const setBranch = useAction(AppActions.setBranch);
  const setColor = useAction(AppActions.setColor);
  const setSeller = useAction(AppActions.setSeller);
  const reset = useAction(AppActions.reset);
  const submit = useAction(AppActions.submit.request);

  // Search params
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    // setModel("Galaxy S24 Ultra");
    if(model.toLocaleLowerCase().includes("pro max")) {
        setMemory('256GB')
    } else {
      setMemory('128GB')
    }
    
    if(color.length > 0) {
      if(model.toLocaleLowerCase().includes('pro max')) {
        setColor('Desert Titanium');
      } else {
        setColor('Ultramarine');
      }
    }
  }, [model])


  useEffect(() => {
    setBranch(searchParams.get('branch') ?? '')
  }, [searchParams])

  if (loading) {
    return <Loading message="Please wait while submitting your order..." />;
  }
  
  return (
    <div style={{}}>
      <div role="form" className="container wpcf7" id="wpcf7-f28664-p28666-o1" dir="ltr">
        { searchParams.get('branch')?.toUpperCase().includes(" HPT") && <div
          className="box-shadow d-flex justify-content-center align-items-center p-5 ms-1 me-1 mt-3"
          style={{ backgroundColor: "black" }}
        >
          <img style={{ width: "50%" }} src={HiPhoneLogoImage} />
        </div>}

        { searchParams.get('branch')?.toUpperCase().includes(" HPC") && <div
          className="box-shadow d-flex justify-content-center align-items-center p-5 ms-1 me-1 mt-3"
          style={{ backgroundColor: "white", border:'2px solid #FAFAFA' }}
        >
          <img style={{ width: "50%" }} src={HiphoneCareLogoImage} />
        </div>}

        { searchParams.get('branch')?.toUpperCase().includes(" PB") && <div
          className="box-shadow d-flex justify-content-center align-items-center p-5 ms-1 me-1 mt-3"
          style={{ backgroundColor: "white", border:'2px solid #FAFAFA' }}
        >
          <img style={{ width: "50%" }} src={PhoneBookLogoImage} />
        </div>}

        { searchParams.get('branch')?.toUpperCase().includes(" MOB") && <div
          className="box-shadow d-flex justify-content-center align-items-center p-5 ms-1 me-1 mt-3"
          style={{ backgroundColor: "white", border:'2px solid #FAFAFA' }}
        >
          <img style={{ width: "50%" }} src={MobicomLogoImage} />
        </div>}

        { !move && <div
          className="box-shadow ms-1 me-1 mt-3 mb-3 p-4"
          style={{ backgroundColor: "#000", color: 'white' }}
        >
          <div style={{ fontWeight: "bold", fontSize: 20 }}>
            Order Apple IPhone 16 Series now.
          </div>
          <div style={{ fontSize: 14 }}>
            Please fill you details below and select your desired device specifications
          </div>
        </div>}
        
        { !move && <div className="ms-1 me-1 mt-3 mb-3">
        <img className="box-shadow" style={{ width: '100%' }} src={PromoImage} />
        </div>}

        {
            move && <div
            className="box-shadow ms-1 me-1 mt-3 mb-3 p-4"
            style={{ backgroundColor: "#fafafa" }}
          >
            <div className="d-flex justify-content-center align-items-center" style={{ fontWeight: "bold", fontSize: 40 }}>
              Thank You
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 16 }}>
              Your requested has been submitted successfully, We will contact you as soon as possible.
            </div>
          </div>
        }
        
        { !move && <div
          className="box-shadow ms-1 me-1 mb-3"
          style={{ backgroundColor: "#f0f0f0", color: 'black' }}
        >
          {/* First name and last name */}
          <div className="container p-4">
            <div className="row mb-3">
              <div className="col-lg-6">
              <div className="mb-1 d-flex label">{t("name")}</div>
                <span>
                  <input
                    disabled={loading}
                    type="text"
                    defaultValue=""
                    size={40}
                    className="input"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
              <div className="col-lg-6">
                <div className="mb-1 d-flex label">{t("phone_number")}<div style={{color:'red'}}>*</div></div>
                <span>
                  <input
                    disabled={loading}
                    type="text"
                    defaultValue=""
                    size={40}
                    className="input"
                    value={phone_number}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>

            {/* Email and phone */}
            <div className="row mb-3">
              <div className="col-lg-6">
              <div className="mb-1 d-flex label">{t("whatsapp_number")}</div>
                <span>
                  <input
                    disabled={false}
                    type="text"
                    defaultValue=""
                    size={40}
                    className="input"
                    id="LastName"
                    value={whatsapp_number}
                    onChange={(event) => {
                      setWhatsappNumber(event.target.value);
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
              <div className="col-lg-6">
              <div className="mb-1 d-flex label">{t("email_address")}</div>
                <span>
                  <input
                    disabled={false}
                    type="text"
                    defaultValue=""
                    size={40}
                    className="input"
                    value={email_address}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>
            </div>

            <div className="row">
              { !searchParams.get('branch')?.toLocaleLowerCase().includes("online request hpt") && <div className="col-lg-6">
              <div className="mb-1 d-flex label">{t("Seller Name")}</div>
                <span>
                  <input
                    disabled={false}
                    type="text"
                    defaultValue=""
                    size={40}
                    className="input"
                    value={seller}
                    onChange={(event) => {
                      setSeller(event.target.value);
                    }}
                    aria-required="true"
                    aria-invalid="false"
                  />
                </span>
              </div>}
              <div className={`col-lg-6`}>
                <div
                  data-id="apple_models"
                  data-orig_data_id="apple_models"
                  data-class="wpcf7cf_group"
                  style={{ height: "auto" }}
                  className="wpcf7cf-hidden"
                >
                  <div className="mb-1 d-flex label">{t("memory")}<div style={{color:'red'}}>*</div></div>
                  <MemorySelection />
                  <p />
                </div>
              </div>
            </div>

            {/* Manufactor and model */}
            <div className="row mb-3">
              <div className="col-lg-6">
              <div className="mb-1 d-flex label">{t("model")}<div style={{color:'red'}}>*</div></div>
                <ModelSelection/>
              </div>

              <div className="col-lg-6">
                <div
                  data-id="apple_models"
                  data-orig_data_id="apple_models"
                  data-class="wpcf7cf_group"
                  style={{ height: "auto" }}
                  className="wpcf7cf-hidden"
                >
                  <label className="label">{t("Color")}</label>
                  <br />
                  { model.toLocaleLowerCase().includes('pro') &&  <ProColorSelection />}
                  { !model.toLocaleLowerCase().includes('pro') &&  <ColorSelection />}
                  <p />
                </div>
              </div>
            </div>
            
          </div>
        </div>}
        { ((error ?? '').length > 0) && <div
          className="box-shadow ms-1 me-1 mb-3 p-4"
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{color: 'red', fontSize: 16}}>{error}</div>
        </div>}
        <div
          className="box-shadow ms-1 me-1 mb-3 p-4"
          style={{
            backgroundColor: "black",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <button
            onClick={() => {
                if(move) {
                    setMove(false)
                } else {
                    submit();
                }
            }}
            className="submit-button p-3 ps-4 pe-4"
            // style={{
            //   fontWeight: "bold",
            //   fontSize: 16,
            //   border: "1px solid #f1f1f1f1",
            //   backgroundColor: "black",
            //   borderRadius: "10px",
            //   color: "white",
            // }}
          >
            { move ? 'Return' : 'Submit Your Request'}
          </button>
        </div>
        <div className="ms-1 me-1 mb-3">
        <img className="box-shadow" style={{ width: '100%',  }} src={Promo3Image} />
        </div>

        <div className="ms-1 me-1 mb-3">
        <img className="box-shadow" style={{ width: '100%',  }} src={Promo2Image} />
        </div>

        {/* <div className="ms-1 me-1 mb-3">
        <img className="box-shadow" style={{ width: '100%',  }} src={Promo1Image} />
        </div> */}
      </div>
      <Footer />
    </div>
  );
};
