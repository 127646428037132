import { useSelector } from "react-redux";
import { AppActions } from "../modules/actions";
import { RootState } from "../store/rootState";
import { useAction } from "../utils/hooks/action";

export const ModelSelection = () => {
    const { model, memory } = useSelector((state: RootState) => state.app);
  const setModel = useAction(AppActions.setModel);
  var data = [
    "Apple iPhone 16",
    "Apple iPhone 16 Plus",
    "Apple iPhone 16 Pro",
    "Apple iPhone 16 Pro Max"
  ];
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {[
        ...(data ?? []).map((item, index) => (
          <div
            onClick={() => {
              setModel(item);
            }}
            className={`selection${(model ?? 'Apple iPhone 16 Pro') === item ? "-selected" : ""}`}
          >
            {item}
          </div>
        )),
      ]}
    </div>
  );
};
