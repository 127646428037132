import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import { HashRouter, BrowserRouter, Route, Routes, Link, Navigate } from "react-router-dom";
import { RequestDevicePage } from "./pages/request_device";
import "bootstrap/dist/css/bootstrap.css";
import "./i18n/config";
import { RedirectPage } from "./pages/redirect";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter basename="/">
          <Routes>
          <Route path="/" element={<RequestDevicePage/>} />
            <Route path="/submit" element={<RequestDevicePage />} />
          </Routes>
        </HashRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
