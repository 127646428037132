import { createReducer } from 'deox';
import { produce } from 'immer';
import {
  AppActions,
} from './actions';
import { AppTypes } from './types';

const initialState: AppTypes = {
  name: '',
  phone_number: '',
  whatsapp_number: '',
  email_address: '',
  model: 'Apple IPhone 16',
  memory: '256GB',
  loading: false,
  move: false,
  error: '',
  color: '',
  branch: '',
  seller: ''
};

export const appReducer = createReducer(initialState, handleAction => [

  handleAction(AppActions.reset, (state) =>
    produce(state, draft => {
      draft.name = ''
      draft.phone_number = ''
      draft.whatsapp_number = ''
      draft.email_address = ''
      draft.model = 'Apple IPhone 16'
      draft.memory = '256GB'
      draft.loading = false
      draft.move = false
      draft.error = ''
    }),
  ),

  handleAction(AppActions.setName, (state, { payload }) =>
    produce(state, draft => {
      draft.name = payload;
    }),
  ),

  handleAction(AppActions.setPhoneNumber, (state, {payload}) =>
    produce(state, draft => {
      draft.phone_number = payload;
    }),
  ),

  handleAction(AppActions.setWhatsappNumber, (state, { payload }) =>
    produce(state, draft => {
      draft.whatsapp_number = payload;
    }),
  ),

  handleAction(AppActions.setEmail, (state, {payload}) =>
    produce(state, draft => {
      draft.email_address = payload;
    }),
  ),

  handleAction(AppActions.setModel, (state, { payload }) =>
    produce(state, draft => {
      draft.model = payload;
    }),
  ),

  handleAction(AppActions.setMemory, (state, {payload}) =>
    produce(state, draft => {
      draft.memory = payload;
    }),
  ),

  handleAction(AppActions.setMove, (state, {payload}) =>
    produce(state, draft => {
      draft.move = payload;
    }),
  ),

  // Submit request
  handleAction(AppActions.submit.request, (state) =>
    produce(state, draft => {
      console.log('triggered reducer')
      draft.loading = true
    }),
  ),

  handleAction(AppActions.submit.success, (state) =>
    produce(state, draft => {
      draft.loading = false
      draft.move = true;
      draft.error = '';
    }),
  ),

  handleAction(AppActions.submit.fail, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false
      draft.error = payload
    }),
  ),

  // Move
  handleAction(AppActions.setMove, (state, { payload }) =>
    produce(state, draft => {
      draft.move = payload;
    }),
  ),

  handleAction(AppActions.setColor, (state, { payload }) =>
    produce(state, draft => {
      draft.color = payload;
    }),
  ),

  handleAction(AppActions.setBranch, (state, { payload }) =>
    produce(state, draft => {
      draft.branch = payload;
    }),
  ),

  handleAction(AppActions.setSeller, (state, { payload }) =>
    produce(state, draft => {
      draft.seller = payload;
    }),
  ),
]);