import { useSelector } from "react-redux";
import { AppActions } from "../modules/actions";
import { RootState } from "../store/rootState";
import { useAction } from "../utils/hooks/action";
// import BlueImage from "../assets/blue.jpeg";
// import YellowImage from "../assets/yellow.jpeg";
// import GreenImage from "../assets/green.jpeg";
// import PinkImage from "../assets/pink.jpeg";
// import BlackImage from "../assets/black.jpeg";

export const ColorSelection = () => {
    const { color } = useSelector((state: RootState) => state.app);
  const setColor = useAction(AppActions.setColor);
  
  
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
            onClick={() => {
              setColor('Ultramarine');
            }}
            className={`color-selection${(color ?? '') === 'Ultramarine' ? "-selected" : ""}`}
          >
            {'Ultramarine'}
            
          </div>

          <div
            onClick={() => {
              setColor('Teal');
            }}
            className={`color-selection${(color ?? '') === 'Teal' ? "-selected" : ""}`}
          >
            {'Teal'}
            
          </div>

          <div
            onClick={() => {
              setColor('Pink');
            }}
            className={`color-selection${(color ?? '') === 'Pink' ? "-selected" : ""}`}
          >
            {'Pink'}
            
          </div>

          <div
            onClick={() => {
              setColor('White');
            }}
            className={`color-selection${(color ?? '') === 'White' ? "-selected" : ""}`}
          >
            {'White'}
            
          </div>

          <div
            onClick={() => {
              setColor('Black');
            }}
            className={`color-selection${(color ?? '') === 'Black' ? "-selected" : ""}`}
          >
            {'Black'}
            
          </div>
    </div>
  );
};
