import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 

// import { resetStore } from 'modules/app/actions';
// import { authReducer } from 'modules/auth/reducer';
// import { appReducer } from 'modules/app/reducer';
// import { userReducer } from 'modules/user/reducer';
// import { seekerReducer } from 'modules/seeker/reducer';
// import { posterReducer } from 'modules/poster/reducer';

import { RootState } from './rootState';
import { appReducer } from '../modules/reducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['app'],
};

const projectReducer = combineReducers({
  app: appReducer,
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return projectReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
