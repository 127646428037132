import './actions';
// import { ActionType } from 'deox';
import { AppActions } from './actions';
import { RootState } from '../store/rootState';
import { AppApis } from '../server/api/App';
import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';


function* submit(): SagaIterator {
  try {
    console.log('submit device request saga');
    const { seller, name, phone_number, whatsapp_number, email_address, model, memory, color, branch } = yield* select((state: RootState) => state.app) as any;

    if(phone_number.length == 0) {
      yield* put(AppActions.submit.fail('Please enter your phone number.')) as any;
      return;
    }
    
    var payload = { 
      "customer_name" : name, 
      "mobile_number" : phone_number,
      "whatsapp_number" : whatsapp_number, 
      "email" : email_address,
      "model" : model,
      "memory" : memory, 
      "branch" : branch, 
      "color" : color,
      "seller" : seller,
      };

      console.log(payload)
    const { data } = yield* call(AppApis.deviceRequest, payload) as any;
      console.log('submit device request response: ');
      console.log(data);
      yield* put(AppActions.submit.success()) as any;

  } catch (error) {
    console.log('submit device request error')
    console.log((error as any).response)
    yield* put(AppActions.submit.fail('Something went wrong while trying to submit your request, Please try again later.')) as any;
  }
}

export function* watchApp(): SagaIterator {
  yield* takeLatest(AppActions.submit.request, submit) as any;
}
