//export const BASE_URL = 'https://najmatigroup.com/hiphone/'; // dev
//export const BASE_URL = 'http://38.242.157.171/~vpassae/hiphone/'; // dev
export const DOMAIN_URL = 'https://hiphonetelecom.com';
export const BASE_URL = DOMAIN_URL + '/v2/';

export const UPLOADS_URL = `${BASE_URL}/uploads/`;

export const DEVICE_REQUEST_URL = 'Api/device_request';

export const validationRegex = {
  telephoneNumber: /^([0-9]{9,15})$/,
  username: /^([A-Za-z0-9.@_]{4,32})$/,
  password: /^(.{8,32})$/,
  positiveNumber: /^\-?\d+((\.|\,)\d+)?$/,
};